import React from 'react'
import BoxStaticPageList from '../../../src/common/components/BoxStaticPageList/BoxStaticPageList'
import LayoutLanding from '../../../src/common/components/layouts/layoutLanding/LayoutLanding'
import { locale, t } from '../../../src/common/../common/i18n'

const ImageAlineacion =
  'https://media.rodi.es/landings/agricola/alineacion-neumaticos-agricolas.jpg'
const ImageAsistencia =
  'https://media.rodi.es/landings/agricola/asistencia-rodi-24h-vehiculos-agricolas.jpg'
const ImageNeumaticos =
  'https://media.rodi.es/landings/agricola/neumaticos-vehiculos-agricolas-tractore.jpg'
const ImageTeleinflado =
  'https://media.rodi.es/landings/agricola/teleinflado-agriwin-neumaticos-agricolas.jpg'

interface Props {
  styles: { [k: string]: string }
}

const sectionsEs = [
  {
    title: 'Productos y Servicios de Mantenimiento',
    items: [
      {
        title: 'Neumáticos Agrícolas',
        description: `Tenemos una amplia selección de neumáticos agrícolas con la 
        mayor variedad de stock del territorio nacional y las mejores marcas del mercado. 
        Más información aquí.`,
        img: ImageNeumaticos,
        route: 'agricola.neumaticos',
      },
      {
        title: 'Alineado de Neumáticos',
        description: `El alineado te ayuda a evitar un desgaste prematuro del neumático y 
        un gasto de combustible innecesario. Más información aquí.`,
        img: ImageAlineacion,
        route: 'agricola.alineacion',
      },
      {
        title: 'Teleinflado Agriwin®',
        description: `Un sistema que permite al conductor del tractor modificar la presión del 
        neumático en función de sus necesidades. Más información aquí.`,
        img: ImageTeleinflado,
        route: 'agricola.teleinflado',
      },
      {
        title: 'Asistencia 24 horas',
        description: `En Rodi dispones de un servicio de atención especializado en vehículos
         agrícolas las 24h los 365 días del año.  Más información aquí.`,
        img: ImageAsistencia,
        route: 'agricola.asistencia',
      },
    ],
  },
]

const sectionCa = [
  {
    title: 'Productes i Serveis de Manteniment',
    items: [
      {
        title: 'Pneumàtics Agrícoles',
        description: `Tenim una àmplia selecció de pneumàtics agrícoles amb la més
         gran varietat d'estoc del territori nacional i les millors marques del mercat. 
         Més informació aquí.`,
        img: ImageNeumaticos,
        route: 'agricola.neumaticos',
      },
      {
        title: 'Alineat de Pneumàtics',
        description: `L’alineat t'ajuda a evitar un desgast prematur dels pneumàtic
         i una despesa de combustible encenser. Més informació aquí.`,
        img: ImageAlineacion,
        route: 'agricola.alineacion',
      },
      {
        title: 'Teleinflado Agriwin®',
        description: ` Un sistema que permet al conductor del tractor modificar la 
        pressió del pneumàtic en funció de les seves necessitats. Més informació aquí.`,
        img: ImageTeleinflado,
        route: 'agricola.teleinflado',
      },
      {
        title: 'Assistència 24 hores',
        description: `A Rodi disposes d'un servei d'atenció especialitzat en vehicles agrícoles
         les 24h els 365 dies de l'any. Més informació aquí.`,
        img: ImageAsistencia,
        route: 'agricola.asistencia',
      },
    ],
  },
]

const AgricolaCA = ({ styles }: Props) => (
  <LayoutLanding
    title="Serveis per al teu vehicle agrícola"
    descriptionCTA={t('landing_agricola.descripcion_cta')}
    textButtonCTA={t('ficha-taller.appointment')}
    ctaLink={{
      cta: 'talleres_mecanicos',
      cta_es: 'LOCALIZA TU TALLER',
      cta_ca: 'LOCALITZA EL TEU TALLER',
    }}>
    <React.Fragment>
      <p>
        A <strong>Rodi Motor Services</strong> disposem de serveis de
        <strong> manteniment per a Vehicles Agrícoles i Tractors</strong>.
        Oferim solucions de
        <strong>mecànica i assistència professional </strong>
        com: canvi de pneumàtics, alineació, assistència en carretera 24h ...
      </p>
      <p>
        A Rodi trobaràs una <strong>àmplia xarxa de tallers </strong>i una
        solució de manteniment adaptada a les necessitats de la teva empresa i
        del teu tractor.
      </p>
      <p>
        A continuació, disposes de tots els serveis que oferim. Dins de cada
        secció, expliquem què és cada element i la seva importància per a què el
        vehicle es mantingui sempre en un estat òptim per realitzar la seva
        funció.
      </p>
      {sectionCa.map((section, index) => (
        <BoxStaticPageList
          key={index}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const AgricolaES = ({ styles }: Props) => (
  <LayoutLanding
    title="Servicios para tu vehículo agrícola"
    descriptionCTA={t('landing_agricola.descripcion_cta')}
    textButtonCTA={t('ficha-taller.appointment')}
    ctaLink={{
      cta: 'talleres_mecanicos',
      cta_es: 'LOCALIZA TU TALLER',
      cta_ca: 'LOCALITZA EL TEU TALLER',
    }}>
    <React.Fragment>
      <p>
        En <strong>Rodi Motor Services</strong> disponemos de servicios de
        <strong> mantenimiento para Vehículos Agrícolas y Tractores</strong>.
        Ofrecemos soluciones de <strong>mecánica</strong> y
        <strong> asistencia profesional </strong>como<strong>: </strong>
        cambio de neumáticos, alineación, asistencia en carretera 24h…
      </p>
      <p>
        En Rodi encontrarás una <strong>amplia red de talleres </strong>y una
        solución de mantenimiento adaptada a las necesidades de tu empresa y de
        tu tractor.
      </p>
      <p>
        A continuación, dispones de todos los servicios que ofrecemos. Dentro de
        cada sección, explicamos qué es cada elemento y su importancia para que
        el vehículo se mantenga siempre en un estado óptimo para realizar su
        función.
      </p>
      {sectionsEs.map(section => (
        <BoxStaticPageList
          key={section.title}
          title={section.title}
          items={section.items}
        />
      ))}
    </React.Fragment>
  </LayoutLanding>
)

const AgricolaContents = ({ ...props }: Props) =>
  locale === 'es' ? <AgricolaES {...props} /> : <AgricolaCA {...props} />

export default AgricolaContents
