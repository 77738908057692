import { graphql } from 'gatsby'
import React from 'react'
import { BreadCrumbItems } from '../../../src/common/components/breadCrumb/LocalizedBreadCrumb'
import Layout from '../../../src/common/components/Layout'
import { t } from '../../../src/common/i18n'
import styles from '../../../src/common/pages/politicaPrivacidad.module.scss'
import { SeoData } from '../../../src/common/types/Seo'
import route from '../../../src/common/utils/route'
import { MenuPrincipal } from '../constants/menu'
import { useSelectedMenu } from '../helpers/customHooks/useSelectedMenu'
import AgricolaContents from '../static/agricola'

interface Props {
  data: {
    seoData: SeoData
  }
}
export default function Index({ data }: Props) {
  useSelectedMenu(MenuPrincipal.AGRICOLA)

  const breadCrumItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
    { display: t('bread_crumb.agricola') },
  ] as BreadCrumbItems

  return (
    <Layout
      selector={false}
      seoData={data.seoData}
      breadCrumItems={breadCrumItems}>
      <AgricolaContents styles={styles} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query agricolaQuery($url: String!) {
    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
